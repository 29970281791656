
<template>
  <div class="library">
    <h2 class="m1">资料库</h2>
    <vs-tabs>
      <vs-tab label="练习动作">
        <vs-card title="练习动作" subtitle="使用练习动作库为你的学员添加演示视频" class="con-tab-ejemplo">
          <!-- list -->
          <vs-table :data="exercises" search max-items="10" pagination>
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <h3>标准练习动作</h3>
              <vs-button
                @click="newExercise"
                color="primary"
                type="flat"
                icon="add"
                class="flex flex-end"
              >添加练习动作</vs-button>
            </div>

            <template slot="thead">
              <vs-th sort-key="exercise_name">名称</vs-th>
              <vs-th>模式</vs-th>
              <vs-th>类型</vs-th>
              <vs-th>主要肌肉</vs-th>
              <vs-th>平面</vs-th>
              <vs-th>创建人</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click.native="editExercise(tr)"
              >
                <vs-td class="list-name">{{ tr.exercise_name }}</vs-td>

                <vs-td>{{tr.tags?tr.tags.pattern.join(','):'' }}</vs-td>

                <vs-td>{{tr.tags? tr.tags.type.join(','):'' }}</vs-td>

                <vs-td>{{tr.tags? tr.tags.primary_muscles.join(','):'' }}</vs-td>

                <vs-td>{{tr.tags? tr.tags.plane.join(','):'' }}</vs-td>

                <vs-td :data="tr.trainer_id">
                  <vs-avatar
                    :src="getTrainerImageURL(tr.trainer_id)"
                    :text="getTrainerUserName(tr.trainer_id)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <!--edit/new-->
          <vs-popup :title="(curExercise && curExercise.id)?'编辑练习 - '+ curExercise.exercise_name:'添加新的练习'" :active.sync="showEditExercise">
            <EditExercise
              ref="refEditExercise"
              :orgExercise="curExercise"
              @close="closeExercise"
              @save="saveExercise"
            />
          </vs-popup>
        </vs-card>
      </vs-tab>

      <vs-tab label="热身动作">
        <vs-card title="热身动作" subtitle="使用热身动作库为你的学员添加热身标准" class="con-tab-ejemplo">
          <!-- list -->
          <vs-table :data="warmups" search max-items="10" pagination>
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <h3>标准热身动作</h3>
              <vs-button
                @click="newWarmup"
                color="primary"
                type="flat"
                icon="add"
                class="flex flex-end"
              >添加热身动作</vs-button>
            </div>

            <template slot="thead">
              <vs-th sort-key="description">名称</vs-th>
              <vs-th>内容</vs-th>
              <vs-th>创建人</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click.native="editWarmup(tr)"
              >
                <vs-td class="list-name">{{ tr.name }}</vs-td>

                <vs-td>{{tr.description}}</vs-td>

                <vs-td :data="tr.trainer_id">
                  <vs-avatar
                    :src="getTrainerImageURL(tr.trainer_id)"
                    :text="getTrainerUserName(tr.trainer_id)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <!--edit/new-->
          <vs-popup :title="(curWarmup && curWarmup.id)?'编辑热身动作 - '+ curWarmup.name:'添加新的热身动作'" :active.sync="showEditWarmup">
            <EditWarmup
              ref="refEditWarmup"
              :orgWarmup="curWarmup"
              @close="closeWarmup"
              @save="saveWarmup"
            />
          </vs-popup>
        </vs-card>
      </vs-tab>

      <vs-tab label="放松动作">
        <vs-card title="放松动作" subtitle="使用放松动作库为你的学员添加放松标准" class="con-tab-ejemplo">
          <!-- list -->
          <vs-table :data="cooldowns" search max-items="10" pagination>
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <h3>标准放松动作</h3>
              <vs-button
                @click="newCooldown"
                color="primary"
                type="flat"
                icon="add"
                class="flex flex-end"
              >添加放松动作</vs-button>
            </div>

            <template slot="thead">
              <vs-th sort-key="description">名称</vs-th>
              <vs-th>内容</vs-th>
              <vs-th>创建人</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click.native="editCooldown(tr)"
              >
                <vs-td class="list-name">{{ tr.name }}</vs-td>

                <vs-td>{{tr.description}}</vs-td>

                <vs-td :data="tr.trainer_id">
                  <vs-avatar
                    :src="getTrainerImageURL(tr.trainer_id)"
                    :text="getTrainerUserName(tr.trainer_id)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <!--edit/new-->
          <vs-popup :title="(curCoolDown && curCoolDown.id)?'编辑放松动作 - '+ curCoolDown.name:'添加新的放松动作'" :active.sync="showEditCooldown">
            <EditCooldown
              v-if="showEditCooldown"
              ref="refEditCooldown"
              :orgCooldown="curCooldown"
              @close="closeCooldown"
              @save="saveCooldown"
            />
          </vs-popup>
        </vs-card>
      </vs-tab>

      <!-- <vs-tab label="指标集">
        <div class="con-tab-ejemplo">指标集</div>
      </vs-tab>

      <vs-tab label="文档">
        <div class="con-tab-ejemplo">文档</div>
      </vs-tab>-->
    </vs-tabs>
  </div>
</template>

<script>
// import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

// //sample data
// import * as sample from "@/store/samples/coach/library/sample_data.js";
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

//commponents
import EditExercise from "@/views/components/coach/Library/EditExercise.vue";
import EditWarmup from "@/views/components/coach/Library/EditWarmup.vue";
import EditCooldown from "@/views/components/coach/Library/EditCooldown.vue";

export default {
  mixins: [baseFun],

  data() {
    return {
      showEditExercise: false,
      showEditWarmup: false,
      showEditCooldown: false,

      curExercise: null,
      curWarmup: null,
      curCoolDown: null,

      // exercises: [],
      // cooldowns: [],
      // warmups: [],
      // trainers: [],
      // users: [],
      // images: [],
      temp: 111
    };
  },

  created() {
    this.$store.commit("coachPageIdx", 2);
    this.$store.dispatch("getExercises", this.activeOrganization.id);
    this.$store.dispatch("getWarmups", this.activeOrganization.id);
    this.$store.dispatch("getCooldowns", this.activeOrganization.id);
  },

  computed: {

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeUser",
      "activeOrganization",
      "exercises",
      "warmups",
      "cooldowns"
    ])
  },

  methods: {
    newExercise() {
      // console.log(this.exercises.length);
      this.curExercise = this.getNewExercise(
        this.activeTrainer.id,
        this.activeOrganization.id
      );
      this.showEditExercise = true;
    },

    // // check library owner, return if can edit
    // checkTrainer(item){
    //   return true;
    //   // let bRtn = item?(item.trainer_id==this.activeTrainer.id):false;
    //   // if (!bRtn) this.$vs.notify({
    //   //   title: "无法编辑",
    //   //   text: "你不是创建人",
    //   //   color: "warning",
    //   //   position: "top-right"
    //   // });
    //   // return bRtn;
    // },

    editExercise(exercise) {
      this.curExercise = exercise;
      this.showEditExercise = true;
    },

    saveExercise() {
      this.$vs.notify({
        title: "成功",
        text: "练习运动保存成功",
        color: "sucess",
        position: "top-right"
      });
      this.showEditExercise = false;
    },

    closeExercise() {
      this.showEditExercise = false;
    },

    newWarmup() {
      this.curWarmup = this.getNewWarmup();
      this.curWarmup.trainer_id = this.activeTrainer.id;
      this.curWarmup.organization_id = this.activeOrganization.id;
      this.showEditWarmup = true;
    },

    editWarmup(warmup) {
      this.curWarmup = warmup;
      this.showEditWarmup = true;
    },

    saveWarmup() {
      this.showEditWarmup = false;
      this.$vs.notify({
        title: "成功",
        text: "热身运动保存成功",
        color: "sucess",
        position: "top-right"
      });
    },

    closeWarmup() {
      this.showEditWarmup = false;
    },

    newCooldown() {
      this.curCooldown = this.getNewCooldown();
      this.curCooldown.trainer_id = this.activeTrainer.id;
      this.curCooldown.organization_id = this.activeOrganization.id;
      this.showEditCooldown = true;
    },

    editCooldown(cooldown) {
      this.curCooldown = cooldown;
      this.showEditCooldown = true;
    },

    saveCooldown() {
      this.showEditCooldown = false;
      this.$vs.notify({
        title: "成功",
        text: "放松运动保存成功",
        color: "sucess",
        position: "top-right"
      });
    },

    closeCooldown() {
      this.showEditCooldown = false;
    }
  },

  components: {
    EditExercise,
    EditWarmup,
    EditCooldown
  }
};
</script>


<style scoped>
.exercise-top-actions {
  padding: 15px 0;
}

.list-name {
  white-space: nowrap;
  font-weight: bold;
}
</style>
