<template>
  <vs-card v-if="exercise">
    <!-- <div slot="header">
      <h4>
        <span v-if="exercise.id">编辑练习 -- {{orgExercise.exercise_name}}</span>
        <span v-else>添加新的练习</span>
      </h4>
    </div> -->

    <vs-row class="mb-6">
      <vs-col vs-w="12">
        <vs-input class="w-full" label="名称*" v-model="exercise.exercise_name" />
      </vs-col>
    </vs-row>

    <vs-row class="mb-6">
      <vs-col vs-w="12">
        <div class="itemPlayer" v-if="currentVideo && currentVideo.attachment_url" height>
          <video-player
            ref="CoolDownPlayer"
            class="media-video-player"
            :options="videoPlayerOptions"
          />
        </div>
        <div>
          <input
            type="file"
            id="files"
            ref="files"
            @change="videoSelected()"
            style="visibility:hidden"
          />
        </div>
      </vs-col>
      <vs-col vs-w="12" class="actions">
        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
          <vs-button
            v-if="exercise && exercise.id && exercise.trainer_id==activeTrainer.id || (exercise.id==0)"
            :disabled="exercise.id==0 && !exercise.exercise_name"
            color="success"
            icon="cloud_upload"
            @click="uploadVideo()">
            上传视频
            </vs-button>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
          <vs-button v-if="cancelSource" color="warning" icon="cancel" @click="cancelUpload()">取消上传</vs-button>
        </vs-col>
      </vs-col>
    </vs-row>

    <!-- <vs-row class="mb-6">
      <vs-col vs-w="12">
        <vs-textarea class="w-full textarea" label="简介*" v-model="exercise.description" />
      </vs-col>
    </vs-row> -->

    <vs-row class="mb-6">
      <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
        <vs-button v-if="!exercise.id || exercise.trainer_id==activeTrainer.id" color="success" type="filled" @click="saveExercise()">保存</vs-button>
      </vs-col>
      <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
        <vs-button v-if="exercise && exercise.id && exercise.trainer_id==activeTrainer.id" color="danger" type="filled" @click="deleteExercise()">删除</vs-button>
        <!-- <DeleteButton v-if="exercise && exercise.id && exercise.trainer_id==activeTrainer.id" :seconds="5" @click="deleteExercise" /> -->
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import baseFun from "@/views/components/coach/baseFunctions.js";

import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

// import DeleteButton from "@/views/components/common/DeleteButton.vue";

export default {
  mixins: [baseFun],

  props: ["orgExercise"],

  data() {
    return {
      exercise: null,
      cancelSource: null
    };
  },

  created() {
    this.exercise = JSON.parse(JSON.stringify(this.orgExercise));
  },

  watch: {
    orgExercise: function(/*newVal, oldVal*/) {
      this.exercise = JSON.parse(JSON.stringify(this.orgExercise));
    }
  },

  computed: {
    currentVideo() {
      if (
        this.exercise &&
        this.exercise.attachments &&
        this.exercise.attachments.length > 0
      ) {
        return this.exercise.attachments[0];
      } else {
        return null;
      }
    },

    videoPlayerOptions() {
      let video = this.currentVideo;
      let playerOptions = {
        width: "460",
        autoplay: true,
        fluid: true,
        muted: true,
        language: "zh",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: []
      };
      if (video) {
        playerOptions.sources = [
          { type: video.type, src: video.attachment_url }
        ];
      }
      return playerOptions;
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "exercises"
    ])
  },

  methods: {
    videoLink() {
      return this.exercise.attachments && this.exercise.attachments.length > 0
        ? this.exercise.attachments[0].attachment_url
        : "";
    },

    cancelUpload() {
      if (this.cancelSource) {
        this.cancelSource.cancel('user canceled.');
        this.cancelSource = null;
      }

      // this.$emit("close");
    },

    saveExercise() {
      if (this.exercise.id) {
        this.$store.dispatch("updateExercise", {
          exercise: this.exercise,
          vm: this
        });
      } else {
        this.$store.dispatch("createExercise", {
          exercise: this.exercise,
          vm: this
        });

        this.exercise = this.getNewExercise(
          this.activeTrainer.id,
          this.activeOrganization.id
        );
      }

      // this.$emit("save");
    },

    deleteExercise() {
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否删除"' + this.exercise.exercise_name + '"？ ',
        accept:this.deleteExerciseProcess
      });
    },

    deleteExerciseProcess(){
      this.$store.dispatch("deleteExercise", {
          exercise: this.exercise,
          vm: this
        });
      this.$emit("save");
    },

    uploadVideo() {
      this.$refs.files.value = '';
      this.$refs.files.click();
    },

    videoSelected() {
      let me = this;
      let $vs = this.$vs;
      this.cancelSource = axios.CancelToken.source();

      // $vs.loading();
      let file = this.$refs.files.files[0];
      let attachment = this.getNewAttachment(
        file.name,
        file.size,
        file.type,
        ""
      );

      let formData = new FormData();
      formData.append("file", file);

      let exercise = this.exercise;
      axios
        .post("/upload/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          cancelToken: this.cancelSource.token
        })
        .then(function(resp) {
          // console.log(resp.data)
          attachment.attachment_url = resp.data.url;
          exercise.attachments = [attachment];

          $vs.loading.close();

          // console.log("SUCCESS!!");
          $vs.notify({
            title: "上传成功",
            text: "视频上传成功",
            color: "success",
            position: "top-right"
          });
        })
        .catch(function(err) {
          // console.log("FAILURE!!" + err);
          $vs.loading.close();

          if(!me.canceled) $vs.notify({
            title: "上传失败",
            text: "视频上传失败：" + err,
            color: "danger",
            position: "top-right"
          });
        });
    }
  },

  components: {
    videoPlayer
  }
};
</script>

<style scoped>
.textarea {
  font-size: 1rem;
}
.videolist {
  padding: 10px 0;
}
.itemPlayer {
  display: block;
  width: 460px;
}
</style>
<style>
.con-vs-dialog {
    z-index: 53001;
}
</style>
