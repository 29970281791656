<template>
  <vs-card v-if="warmup">
    <!-- <div slot="header">
      <h4>
        <span v-if="warmup.id">编辑热身动作 -- {{orgWarmup.name}}</span>
        <span v-else>添加新的热身动作</span>
      </h4>
    </div> -->

    <vs-row class="mb-6">
      <vs-col vs-w="12">
        <vs-input class="w-full" label="名称*" v-model="warmup.name"  :disabled="!editable"/>
      </vs-col>
    </vs-row>

    <vs-row class="mb-6">
      <vs-col vs-w="12">
        <vs-textarea class="w-full" label="简介*" height="200" v-model="warmup.description"  :disabled="!editable"/>
      </vs-col>
    </vs-row>

    <vs-row class="mb-6">
      <vs-col vs-w="12">
        <VueSimpleSuggest
          :list="exerciseSuggestionList"
          :filter-by-query="true"
          @select="linkWarmupExercise"
          placeholder="链接更多演示视频"
          :maxSuggestions="5"
          class="suggestion_list"
          v-model="selecteVideo"
          :disabled="!editable"
        />
      </vs-col>
      <vs-col vs-w="12" class="videolist">
        <vs-chip
          v-for="(demo, dIdx) in warmup.selected_exercises"
          :key="dIdx"
          color="dark"
          closable
          @click="removeWarmupExercise(demo)"
        >
          <vs-avatar v-if="warmupExerciseID==demo.id" @click="warmupExerciseID = null" icon="visibility_off" />
          <vs-avatar v-else @click="warmupExerciseID==demo.id?null:demo.id" icon="visibility" />
          {{demo.name}}
        </vs-chip>
      </vs-col>
    </vs-row>

    <vs-row class="mb-6" v-if="currentVideo && currentVideo.attachment_url">
      <div class="itemPlayer">
        <video-player
          ref="WarmupPlayer"
          class="media-video-player"
          :options="videoPlayerOptions()"
        />
      </div>
    </vs-row>

    <vs-row class="mb-6">
      <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start">
        <vs-button v-if="!warmup.id || warmup.trainer_id==activeTrainer.id" color="success" type="filled" @click="saveWarmup()">保存</vs-button>
        <!-- <vs-button color="#000" type="line" @click="cancelWarmup()">取消</vs-button> -->
      </vs-col>
      <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end">
        <vs-button v-if="warmup && warmup.id && warmup.trainer_id==activeTrainer.id" color="danger" type="filled" @click="deleteWarmup">删除</vs-button>
        <!-- <DeleteButton v-if="warmup && warmup.id && warmup.trainer_id==activeTrainer.id" :seconds="5" @click="deleteWarmup" /> -->
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
// import axios from "axios";
import { mapState } from "vuex";

import baseFun from "@/views/components/coach/baseFunctions.js";

import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS

import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

// import DeleteButton from "@/views/components/common/DeleteButton.vue";

export default {
  mixins: [baseFun],

  props: ["orgWarmup"],

  data() {
    return {
      warmup: null,
      warmupExerciseID: null,
      selecteVideo: "",
      videoSelected: false
    };
  },

  created() {
    this.warmup = JSON.parse(JSON.stringify(this.orgWarmup));
  },

  watch: {
    orgWarmup: function(/*newVal, oldVal*/) {
      this.warmup = JSON.parse(JSON.stringify(this.orgWarmup));
    },

    selecteVideo(newVal){
      if (newVal && this.videoSelected) {
        this.videoSelected = false;
        this.selecteVideo = "";
      }
    }
  },

  computed: {
    editable(){
      return !this.warmup || (this.warmup && this.warmup.trainer_id == this.activeTrainer.id);
    },

    currentVideo(){
      let exercise = this.getExercises(this.warmupExerciseID);
      if (exercise && exercise.attachments && exercise.attachments.length > 0) {
        return exercise.attachments[0];
      } else {
        return null;
      }
    },

    exerciseSuggestionList() {
      let list = [];
      if (this.exercises)
        this.exercises.forEach(itm => list.push(itm.exercise_name));
      return list;
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "exercises",
      "warmups"
    ])
  },

  methods: {
    //link more videos to warmup
    linkWarmupExercise(ex_name) {
      if (ex_name){
        let item = this.getExerciseByName(ex_name);
        if (item) {
          this.videoSelected = true;
          let new_item = { id: item.id, name: item.exercise_name };
          if (
            !this.warmup.selected_exercises.find(itm => itm.id == new_item.id)
          ) {
            this.warmup.selected_exercises.push(new_item);
          }
        }
      }
    },

    videoPlayerOptions() {
      let video = this.currentVideo;
      let playerOptions = {
        width: "460",
        autoplay: true,
        fluid: true,
        muted: true,
        language: "zh",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: []
      };
      if (video) {
        playerOptions.sources = [
          { type: video.type, src: video.attachment_url }
        ];
      }
      return playerOptions;
    },

    removeWarmupExercise(ex_item) {
      let idx = this.warmup.selected_exercises.indexOf(ex_item);
      if (idx >= 0) {
        this.warmup.selected_exercises.splice(idx, 1);
      }

      //reset player
      if (ex_item.id == this.warmupExerciseID) {
        this.warmupExerciseID = null;
      }
    },

    cancelWarmup() {
      this.$emit("close");
    },

    saveWarmup() {
      if (this.warmup.id) {
        this.$store.dispatch("updateWarmup", { warmup: this.warmup, vm: this });
      } else {
        this.$store.dispatch("createWarmup", { warmup: this.warmup, vm: this });
        this.warmup = this.getNewWarmup(
          this.activeTrainer.id,
          this.activeOrganization.id);
      }

      // this.$emit("save", this.warmup);
    },

    deleteWarmup(){
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否删除"' + this.warmup.name + '"？ ',
        accept:this.deleteWarmupProcess
      });
    },

    deleteWarmupProcess(){
      this.$store.dispatch("deleteWarmup", {
          warmup: this.warmup,
          vm: this
        });
      this.$emit("save");
    }
  },

  components: {
    VueSimpleSuggest,
    videoPlayer
  }
};
</script>

<style scoped>
.videolist {
  padding: 10px 0;
}
.itemPlayer {
  display: block;
  width: 460px;
}
</style>

<style>
.con-vs-dialog {
    z-index: 53001;
}
</style>
